export const formatAsistenciaToclipboard = (a) => {
  let clipboard = "";
  clipboard += `Asistencia ID: ${a.asistenciaID}\n`;

  clipboard += `Nombre: ${a.nombreLlamada} ${a.apellidoPaternoLlamada} ${a.apellidoMaternoLlamada} \n`;

  clipboard += `Servicio: ${a.servicio?.nombre}\n`;

  if (a.campo1 !== "")
  clipboard += `${a.campo1}\n`;

  if (a.campo2 !== "")
  clipboard += `${a.campo2}\n`;

  if (a.campo3 !== "")
  clipboard += `${a.campo3}\n`;

  if (a.campo4 !== "")
  clipboard += `${a.campo4}\n`;

  if (a.campo5 !== "")
  clipboard += `${a.campo5}\n`;

  if (a.campo6 !== "")
  clipboard += `${a.campo6}\n`;

  if (a.campo7 !== "")
  clipboard += `${a.campo7}\n`;

  if (a.campo8 !== "")
  clipboard += `${a.campo8}\n`;

  if (a.campo9 !== "")
  clipboard += `${a.campo9}\n`;

  if (a.campo10 !== "")
  clipboard += `${a.campo10}\n`;

  if (a.campo11 !== "")
  clipboard += `${a.campo11}\n`;

  if (a.campo12 !== "")
  clipboard += `${a.campo12}\n`;

  clipboard += `Direccion Origen: ${a.direccionOrigen}\n`;
  if (a.estadoDestino !== "")
    clipboard += `Direccion Destino destino: ${a.direccionDestino}\n`;

  clipboard += `LatLng origen: ${a.latOrigen}, ${a.lngOrigen}\n`;
  if (a.latDestino !== "")
    clipboard += `LatLng destino: ${a.latDestino}, ${a.lngDestino}\n`;

  return clipboard;
};
