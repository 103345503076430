import { Button, Col, Divider, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { LabelValor } from "../../../../components/label/LabelValor";
import CardPage from "../../../../layout/CardPage";
import { Controles } from "../controles/Controles";
import { SplitCampo } from "./SplitCampo";
import { QR } from "./QR";
import { TiempoTranscuridoArribo } from "./_TiempoTranscuridoArribo";
import { CopyOutlined } from "@ant-design/icons";
import { formatAsistenciaToclipboard } from "../../../../utils/formatAsistenciaToclipboard";

export const DatosAsistencia = () => {
  const [copiado, setCopiado] = React.useState(false);
  const history = useHistory();
  const { asistencia: a } = useSelector((state) => state.asistenciasReducer);

  const copiar = () => {

    setCopiado(true);
    navigator.clipboard.writeText(formatAsistenciaToclipboard(a));

    setTimeout(() => {
      setCopiado(false);
    } , 2000);


  };
  //
  return (
    <CardPage
      titulo="Datos de Asistencia"
      tituloBoton="Regresar"
      accionBoton={() => history.push("/")}
    >
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={6}>
          <LabelValor
            label="Titular"
            valor={`${a.nombreLlamada} ${a.apellidoPaternoLlamada}`}
          />
        </Col>
        <Col span={6}>
          <LabelValor label="Servicio" valor={a.servicio?.nombre} />
        </Col>

        <Col span={6}>
          <LabelValor label="Estatus" valor={a.estatus?.nombre} />
        </Col>

        <SplitCampo campo={a.campo1} />
        <SplitCampo campo={a.campo2} />
        <SplitCampo campo={a.campo3} />
        <SplitCampo campo={a.campo4} />
        <SplitCampo campo={a.campo5} />
        <SplitCampo campo={a.campo6} />
        <SplitCampo campo={a.campo7} />
        <SplitCampo campo={a.campo8} />
        <SplitCampo campo={a.campo9} />
        <SplitCampo campo={a.campo10} />
        <SplitCampo campo={a.campo11} />
        <SplitCampo campo={a.campo12} />
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={6}>
          <LabelValor
            label="Estado origen"
            valor={`${a.municipioOrigen}, ${a.estadoOrigen}`}
          />
        </Col>

        {a.estadoDestino !== "" && (
          <Col span={6}>
            <LabelValor
              label="Estado destino"
              valor={`${a.municipioDestino}, ${a.estadoDestino}`}
            />
          </Col>
        )}
      </Row>

      <Row>
        <Col span={18}>
          <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
            <Col span={12}>
              <LabelValor
                label="LatLng origen"
                valor={`${a.latOrigen}, ${a.lngOrigen}`}
              />
            </Col>

            {a.latDestino !== "" && (
              <Col span={12}>
                <LabelValor
                  label="LatLng destino"
                  valor={`${a.latDestino}, ${a.lngDestino}`}
                />
              </Col>
            )}
          </Row>
          <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
            <Col span={12}>
              <LabelValor label="Dirección origen" valor={a.direccionOrigen} />
            </Col>

            {a.direccionDestino !== "" && (
              <Col span={12}>
                <LabelValor
                  label="Dirección destino"
                  valor={a.direccionDestino}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col span={12}>
              <TiempoTranscuridoArribo />
            </Col>
            <Col span={12} style={{ paddingTop: 10, paddingLeft: 20 }}>
              <Button onClick={copiar} type="primary" icon={<CopyOutlined />}>
                Copiar Datos
              </Button>
              {copiado && (
                <div
                  style={{
                    marginTop: 4,
                    marginLeft: 10,
                    color: "#1677FF",
                    fontWeight: 600,
                  }}
                >
                  ¡ Datos copiados !
                </div>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <QR />
        </Col>
      </Row>

      <Divider />
      <Controles />
    </CardPage>
  );
};
